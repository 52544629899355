import React from "react";
import logoMain from "../../../assets/images/logo-1.svg";
import expand_right from "../../../assets/images/icons/expand_right.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import LayoutHome from "../../Layout/LayoutHome";
import LayoutSymtom from "../../Layout/LayoutSymtom";
import LpSymtomCheckerTwo from "../lp_symtom_checker/LpSymtomCheckerTwo";
import TrustPilot from "./TrustPilot";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";



export default function Homepage() {
  const navigate = useNavigate();
  const handleDentistClick = () => {
    if (sessionStorage.getItem("token")) {
      navigate("/symptom-checker-start");
    } else {
      navigate("/userlogin");
    }
  };

  const reviews = [
    {
      title: "Great for Emergencies!",
      description:
        "Had severe tooth pain, and within hours I was connected to a dentist. Managed to get a prescription immediately—such a relief!",
      rating: "★★★★★",
    },
    {
      title: "Lifesaver for Toothache",
      description:
        "Easy to use and got painkillers for my toothache right away. Helped me avoid a sleepless night!",
      rating: "★★★★★",
    },
    {
      title: "Quick and Efficient",
      description:
        "Swollen gum fixed with fast advice and antibiotics. Super convenient for emergency help.",
      rating: "★★★★★",
    },
    {
      title: "Super Fast Service",
      description:
        "Had a dental emergency on the weekend. Connected to a dentist in no time, and got advice that really helped me identify the cause was my wisdom tooth.",
      rating: "★★★★★",
    },
    {
      title: "Excellent for Immediate Care",
      description:
        "The consultation was fast, and they sent my prescription right away. So helpful in an emergency!",
      rating: "★★★★★",
    },
    {
      title: "Convenient and Professional",
      description:
        "I had a terrible toothache late at night, and this service connected me to a dentist the next day — super convenient!",
      rating: "★★★★★",
    },
    {
      title: "Fast Response and Great Advice",
      description:
        "Got advice and a prescription for my infected tooth in under 30 minutes! The dentist was friendly, and the process was smooth.",
      rating: "★★★★★",
    },
    {
      title: "Excellent Experience",
      description:
        "The consultation was easy to book, and the dentist was very knowledgeable. They helped me with some sensitivity and gum pain.",
      rating: "★★★★★",
    },
    {
      title: "Perfect for Urgent Dental Help",
      description:
        "I had a chipped tooth and was panicking, but this service made everything easier. The dentist reassured me and gave helpful advice.",
      rating: "★★★★★",
    },
    {
      title: "Super Convenient and Reliable",
      description:
        "Used the online consultation for a sudden toothache while traveling. The dentist provided a quick diagnosis and sent a prescription my way.",
      rating: "★★★★★",
    },
  ];

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20px",
        }
      }
    ]
  };

  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <>
      <LayoutHome>
        <div id="wrapper">
          <section className="banner-img">
            <div className="container-fluid container_inner_width">
              <div className="banner-content">
                <h1 className="mb-0">
                  Get Expert Advice <br />
                  for Dental Emergencies
                </h1>
                <h2 className="mt-3">
                  Get a provisional diagnosis* within minutes
                </h2>
                <div className="banner-ad-btns">
                  <Link
                    to="/lp-symtom-checker-one"
                    className="btn btn-primary border-radius-50 d-flex white-btn"
                  >
                    Free Symptom Checker
                  </Link>
                  <div>

                    <button className="arrow-button" onClick={handleButtonClick}>
                      <span className="button-arrow">

                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.1211 6L25.1211 15L16.1211 24" stroke="#fff" stroke-miterlimit="20" />
                          <path d="M25.12 15H5" stroke="#fff" stroke-miterlimit="20" />
                        </svg>

                      </span>
                      Book Consultation £19.99
                    </button>
                  </div></div>
              </div>
            </div>
          </section>


          {/* <!-- banner  end --> 
        <!-- common banner bottom part start --> */}
          <section className="trust_wrapper position-relative">
            <div className="banner_btm">
              {/* <div className="btm_logo">
                <img src={logoMain} alt="bottom-logo" className="img-fluid" />
              </div> */}
              <TrustPilot />
              <div className="btm_ques">
                <p
                  onClick={() => handleDentistClick()}
                  className="book_dentist_link"
                >
                  Having issues? Book in online{" "}
                  <img src={expand_right} alt="right-arrow" />
                </p>
              </div>
            </div>
          </section>

          <Modal show={showModal} onHide={handleCloseModal} centered>

            <Modal.Body>
              <div className="new-banner-pop">
                <h3>Book Consultation</h3>
                <p>We just need a few more details to book a consultation <br/> with one of our experts.</p>
                <p>Please create an account to continue.</p>
                <div>
                  <button className="white-btn border-none" onClick={handleCloseModal}>
                    Close
                  </button>
                  <button className="dark_btn border-none" onClick={() => navigate("/userlogin")}>Create Account</button>
                </div>
              </div>
            </Modal.Body>

          </Modal>
          {/* <!-- common banner bottom part end -->
        
        <!-- Tooth-aid problems start --> */}
          <section className="tooth-aid-prblms common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-center main-page-titles">
                    Are you experiencing any of the following?
                  </h2>
                  <div className="problems-content hm-prblm-sect">
                    <div className="row">
                      <div className="col-md-6 inner_prblm_detail inner-prblm_img d-flex justify-content-end prblm_img">
                        <div
                          onClick={() =>
                            navigate("/lp-symtom-checker-dental-pain")
                          }
                          className="position-relative img_text"
                        >
                          <img
                            src={require("../../../assets/images/newImages/DentalPain.jpg")}
                            className="img-fluid"
                            alt="tooth-ache"
                          />
                          <h3 className="position-absolute absolute-txt">
                            Dental pain
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-6 inner_prblm_detail inner-prblm_img_end d-flex justify-content-start prblm_img">
                        <div
                          onClick={() =>
                            navigate("/lp-symtom-checker-jaw-ache")
                          }
                          className="position-relative img_text"
                        >
                          <img
                            src={require("../../../assets/images/newImages/JawAche.jpg")}
                            className="img-fluid"
                            alt="abcesses"
                          />
                          <h3 className="position-absolute absolute-txt">
                            Jaw Ache
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-6 inner_prblm_detail inner-prblm_img d-flex justify-content-end prblm_img">
                        <div
                          onClick={() =>
                            navigate("/lp-symtom-checker-headaches-migration")
                          }
                          className="position-relative img_text"
                        >
                          <img
                            src={require("../../../assets/images/newImages/Headaches.jpg")}
                            className="img-fluid"
                            alt="headaches"
                          />
                          <h3 className="position-absolute absolute-txt">
                            Headaches/Migraines
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-6 inner_prblm_detail inner-prblm_img_end d-flex justify-content-start prblm_img">
                        <div
                          onClick={() => navigate("/lp-symtom-checker-ulcers")}
                          className="position-relative img_text"
                        >
                          <img
                            src={require("../../../assets/images/newImages/Ulcers.jpg")}
                            className="img-fluid"
                            alt="ulcers"
                          />
                          <h3 className="position-absolute absolute-txt">
                            Ulcers
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="problems-content">
                  <div className="row">
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/tooth-ache.png")}
                          className="img-fluid"
                          alt="tooth-ache"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Tooth Ache
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/ulcers.png")}
                          className="img-fluid"
                          alt="ulcers"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Ulcers
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/imp-issues.png")}
                          className="img-fluid"
                          alt="imp-issues"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Implant Issues
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/dent-issue.png")}
                          className="img-fluid"
                          alt="denture-isssue"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Denture Issues
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/swelling.png")}
                          className="img-fluid"
                          alt="swelling"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Swelling
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/abcesses.png")}
                          className="img-fluid"
                          alt="abcesses"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Abcesses
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/bleeding-gums.png")}
                          className="img-fluid"
                          alt="bleeding-gums"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Bleeding Gums
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/cracked-tooth.png")}
                          className="img-fluid"
                          alt="cracked-tooth"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Cracked Tooth
                        </h3>
                      </a>
                    </div>
                    <div onClick={() => navigate("/lp-symtom-checker-one")} className="col-4 inner_prblm_detail">
                      <a href="#" className="position-relative img_text">
                        <img
                          src={require("../../../assets/images/problems/filling-issue.png")}
                          className="img-fluid"
                          alt="filling-issue"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Filling Issues
                        </h3>
                      </a>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Tooth-aid problems end -->

        <!-- About-us start --> */}
          <section className="about-us common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-6 justify-content-center align-items-center d-flex logo_none logo_width">
                  <img
                    src={require("../../../assets/images/blck-logo.png")}
                    alt="black-logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="about-us-title d-flex justify-content-between align-items-center mb-3">
                    <h2>What we do</h2>
                    <div className="side_logo">
                      <img
                        src={require("../../../assets/images/logo-short.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>
                    We have worked hard to be able to bring you the Formula 1 of
                    emergency dental treatment. Book with us now to help rapidly
                    diagnose and treat your dental pain.{" "}
                  </p>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Duis viverra mi et diam cursus consectetur. Etiam ac congue
                    nunc. Aenean id dignissim nulla. Vivamus suscipit iaculis
                    turpis, vel ornare diam. Mauris blandit dapibus arcu, in
                    ultricies mauris molestie non. Integer tincidunt tellus quis
                    dolor sagittis, in hendrerit ligula egestas.{" "}
                  </p> */}
                  <div className="d-flex justify-content-end learn_more_btn">
                    <Link to="/about" className="btn btn-primary white-btn">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- About-us end -->

        <!-- Video-Section start --> */}
          <section className="video_data position-relative">
            <div className="videoCoverImage">
              <div>
                <img
                  className="thumb"
                  style={{ cursor: "pointer" }}
                  src={require("../../../assets/images/blogs/video-thumb.png")}
                />
              </div>
              <div id="thevideo" style={{ display: "none" }}>
                <iframe
                  width="100%"
                  height="640"
                  src="https://www.youtube.com/embed/fm208EgQUoU"
                  frameBorder="0"
                  allowFullScreen=""
                  include=""
                  control=""
                ></iframe>
              </div>
            </div>
          </section>
          {/* <!-- Video-Section end -->

        <!-- Discover-Section start --> */}
          <section className="discover common-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-center main-page-titles">Discover</h2>
                  <div className="discover-content">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-4 features">
                        <img
                          src={require("../../../assets/images/newImages/VideoConsultation.jpg")}
                          className="img-fluid"
                          alt="video-consultation"
                        />
                        <h3>
                          Video <br />
                          Consultation
                        </h3>
                      </div>
                      <div className="col-md-2 col-4 features">
                        <img
                          src={require("../../../assets/images/newImages/OnlinePrescriptions.jpg")}
                          className="img-fluid"
                          alt="online-prescription"
                        />
                        <h3>
                          Online <br />
                          Prescriptions
                        </h3>
                      </div>
                      <div className="col-md-2 col-4 features">
                        <img
                          src={require("../../../assets/images/newImages/IndustryUpdates.jpg")}
                          className="img-fluid"
                          alt="industry-updates"
                        />
                        <h3>
                          Industry <br />
                          Updates
                        </h3>
                      </div>
                      <div className="col-md-2 col-4 features">
                        <img
                          src={require("../../../assets/images/newImages/ExpertGuidance.jpg")}
                          className="img-fluid"
                          alt="expert-guidance"
                        />
                        <h3>
                          Expert <br />
                          Guidance
                        </h3>
                      </div>
                      <div className="col-md-2 col-4 features">
                        <img
                          src={require("../../../assets/images/newImages/TreatmentsAdvice.jpg")}
                          className="img-fluid"
                          alt="treatments-advice"
                        />
                        <h3>
                          Treatments & <br />
                          Advice
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Discover-Section end -->

        <!-- Logos-Section start --> */}
          <section className="logos common-padding">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="logo-img">
                    <img
                      src={require("../../../assets/images/logos/bbc-logo.png")}
                      alt="bbc-logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="logo-img">
                    <img
                      src={require("../../../assets/images/logos/daily-mail.png")}
                      alt="daily-mail"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* <div className="col-4">
                  <div className="logo-img">
                    <img
                      src={require("../../../assets/images/logos/guardian-logo.png")}
                      alt="guardian-logo"
                      className="img-fluid"
                    />
                  </div>
                </div> */}
                <div className="col-4">
                  <div className="logo-img">
                    <img
                      src={require("../../../assets/images/logos/cosmopolitan.png")}
                      alt="cosmopolitan"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--  Logos-Section end -->

        <!-- Testimonial start --> */}
          <section className="testimonial ">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4  d-none d-lg-block">
                  {/* <ol className="carousel-indicators tabs">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      className="active"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/1.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/2.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/3.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                  </ol> */}
                </div>
                {/* <div className="col-lg-4 d-flex justify-content-center align-items-center">
                  <div
                    id="carouselExampleIndicators"
                    data-interval="false"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/1-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>“ New Reviews will show here “</p>

                        </div> 

                      </div>

                      
                      <div className="carousel-item">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/2-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>
                            “ ToothAid came to the rescue when I couldn’t get an
                            appointment with my local practitioner. Great
                            service. “
                          </p>
                          <h3>Tim, Suffolk</h3>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/3-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>
                            “ ToothAid came to the rescue when I couldn’t get an
                            appointment with my local practitioner. Great
                            service. “
                          </p>
                          <h3>Tim, Suffolk</h3>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/4-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>
                            “ ToothAid came to the rescue when I couldn’t get an
                            appointment with my local practitioner. Great
                            service. “
                          </p>
                          <h3>Tim, Suffolk</h3>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/5-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>
                            “ ToothAid came to the rescue when I couldn’t get an
                            appointment with my local practitioner. Great
                            service. “
                          </p>
                          <h3>Tim, Suffolk</h3>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="quote-wrapper">
                          <img
                            src={require("../../../assets/images/testimonial/6-lg.png")}
                            className="img-fluid"
                            alt="testimonial-img"
                          />
                          <p>
                            “ ToothAid came to the rescue when I couldn’t get an
                            appointment with my local practitioner. Great
                            service. “
                          </p>
                          <h3>Tim, Suffolk</h3>
                        </div>
                      </div>
                    </div>
                    <ol className="carousel-indicators indicators">
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        className="active"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="1"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="2"
                      ></li>
                    </ol>
                  </div>
                </div> */}

                <div >
                  <Slider {...settings}>
                    {reviews.map((review, index) => (
                      <div key={index} className="quote-wrapper new-slider">
                        <img
                          src={require("../../../assets/images/testimonial/1-lg.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                        <p className="center">{review.description}</p>
                        <p className="center">{review.rating}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="col-lg-4  d-none d-lg-block">
                  {/* <ol className="carousel-indicators tabs">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="3"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/4.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="4"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/5.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="5"
                    >
                      <figure>
                        <img
                          src={require("../../../assets/images/testimonial/6.png")}
                          className="img-fluid"
                          alt="testimonial-img"
                        />
                      </figure>
                    </li>
                  </ol> */}
                  {/* <!-- <ol className="carousel-indicators indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1" className=""></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2" className=""></li>
                            </ol> --> */}
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Testimonial start / end-->
            
        <!-- Call-to-action start --> */}
          {/* <!-- Call-to-action end -->
        
        <!-- Footer Start --> */}
          {/* <!-- Footer End --> */}
        </div >
      </LayoutHome >
    </>
  );
}
